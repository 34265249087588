import React from "react"

import SimpleReactLightbox from "simple-react-lightbox"
import { SRLWrapper } from "simple-react-lightbox"
import Image from "gatsby-plugin-sanity-image"

const options = {
  settings: {},
  caption: {
    showCaption: false,
  },
  buttons: {},
  thumbnails: {},
}
const ThumbnailGallery = ({ gallery }) => {
  return (
    <SimpleReactLightbox>
      <SRLWrapper options={options}>
        <div className="grid grid-cols-6 gap-5 relative z-10">
          {gallery._rawImages.map((picture, index) => (
            <div className="col-span-2 md:col-span-1" key={index}>
              <div
                className="w-20 h-20 xl:w-40 xl:h-40"
                data-aos="zoom-in-down"
                data-aos-delay="300"
              >
                <Image
                  // pass asset, hotspot, and crop fields
                  {...picture}
                  width={500}
                  alt={picture.alt}
                  className="diplomas-thumbs w-30 h-30 rounded shadow nodraggable"
                  // style it how you want it
                  style={{
                    objectFit: "cover",
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </SRLWrapper>
    </SimpleReactLightbox>
  )
}

export default ThumbnailGallery
