import React, { useEffect } from "react"
// import logo from "../images/olga_amosova_name.png"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
// import { BiDownArrow } from "react-icons/bi"
import Image from "gatsby-plugin-sanity-image"
// import Countdown from "react-countdown"
import PortableText from "../components/PortableText"
import { FaVk } from "react-icons/fa"
import { FaFacebook } from "react-icons/fa"
import { FaBookReader } from "react-icons/fa"
import { FaYoutube } from "react-icons/fa"
import { FaSoundcloud } from "react-icons/fa"
import { RiInstagramFill } from "react-icons/ri"
import { FaDiceOne } from "react-icons/fa"
import { SiAnchor } from "react-icons/si"
import { SiApplepodcasts } from "react-icons/si"
import { SiGooglepodcasts } from "react-icons/si"
import { SiBreaker } from "react-icons/si"
import { SiSpotify } from "react-icons/si"

import YouTubePlayer from "../components/Youtube"
import SoundCloudPlayer from "../components/SoundCloud"
import ThumbnailGalleryCoomponent from "../components/ThumbnailGallery"
import { Link } from "gatsby"

import Aos from "aos"

import amosovaword from "../images/amosova.png"
import olgaword from "../images/olga.png"
// import ConcertProgramButton from "../components/ConcertProgramButton"

const IndexPage = ({ data }) => {
  const image = getImage(data.placeholderImage)
  const bgImage = convertToBgImage(image)

  const {
    header,
    date,
    _rawBody,
    _rawAudiotext,
    _rawContactImage,
    _rawHeaderImage,
    _rawLetter,
    mainImage,
    gallery,
    sponsorList,
    soundcloud,
    instagram,
    facebook,
    youTubeLink,
    vk,
    youtube,
    applePodcasts,
    breaker,
    soundcloudplaylist,
    spotify,
    anchor,
    googlePodcasts,
  } = data.sanityHome

  const image2 = getImage(mainImage.asset)
  const bgImage2 = convertToBgImage(image2)

  const Completionist = () => (
    <Link
      to="/concert-program"
      className="bg-red-900 p-5 rounded-xl text-white text-xl font-sans uppercase hover:bg-red-800 shadow"
    >
      Посмотреть программу
    </Link>
  )
  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <Completionist />
    } else {
      return (
        <div className="flex">
          <div className="counter" data-aos="fade-down" data-aos-delay="300">
            <div className="block">
              <div className="text-center">{days}</div>
              <div className="text-sm md:text-xl">дни</div>
            </div>
          </div>
          <div className="counter" data-aos="fade-down" data-aos-delay="400">
            <div className="block">
              <div className="text-center">{hours}</div>
              <div className="text-sm md:text-xl">часы</div>
            </div>
          </div>
          <div className="counter" data-aos="fade-down" data-aos-delay="500">
            <div className="block">
              <div className="text-center">{minutes}</div>
              <div className="text-sm md:text-xl">минуты</div>
            </div>
          </div>
          <div className="counter" data-aos="fade-down" data-aos-delay="600">
            <div className="block">
              <div className="text-center">{seconds}</div>
              <div className="text-sm md:text-xl">секунды</div>
            </div>
          </div>
        </div>
      )
    }
  }

  useEffect(() => {
    Aos.init({ duration: 1000, once: true })
  }, [])
  const ogImagePath = `${_rawHeaderImage.asset.url}?w=1200&h=627&fm=jpg`

  return (
    <Layout>
      <Seo
        title={data.sanitySeoData.title}
        description={data.sanitySeoData.description}
        image={ogImagePath}
      />

      <div className="bg-red-700">
        <div className="container mx-auto h-20 bg-red-700">
          <div className="grid grid-cols-2">
            <div className="col-span-2 lg:col-span-1 bg-red-800 flex items-center justify-center h-20">
              <div className="text-6xl text-white font-sans relative">
                <img
                  src={amosovaword}
                  alt="Amosova"
                  width="350px"
                  data-aos="fade-left"
                  className="nodraggable"
                />
                <img
                  src={olgaword}
                  alt="Olga"
                  width="300px"
                  className="absolute z-20 opacity-5 nodraggable px-5"
                  data-aos="fade-right"
                  style={{ top: "54px", left: "34px" }}
                />
              </div>
            </div>
            <div className="col-span-2 lg:col-span-1 invisible lg:visible align-bottom flex items-center justify-center">
              {/* 1111 */}
              <div className="grid grid-cols-5 gap-5 content-between w-full pb-20 md:pb-0">
                <div
                  className="col-span-1 flex items-center justify-center"
                  data-aos="zoom-in-down"
                  data-aos-delay="300"
                >
                  <span>
                    <a
                      href={youtube}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="youtube"
                    >
                      <FaYoutube className="social-icon" />
                    </a>
                  </span>
                </div>
                <div
                  className="col-span-1 flex items-center justify-center"
                  data-aos="zoom-in-down"
                  data-aos-delay="300"
                >
                  <span>
                    <a
                      href={instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="instagram"
                    >
                      <FaDiceOne className="social-icon" />
                    </a>
                  </span>
                </div>
                <div
                  className="col-span-1 flex items-center justify-center"
                  data-aos="zoom-in-down"
                  data-aos-delay="300"
                >
                  <span>
                    <a
                      href={vk}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="vk"
                    >
                      <FaVk className="social-icon" />
                    </a>
                  </span>
                </div>
                <div
                  className="col-span-1 flex items-center justify-center"
                  data-aos="zoom-in-down"
                  data-aos-delay="300"
                >
                  <span>
                    <a
                      href={soundcloud}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="soundcloud"
                    >
                      <FaSoundcloud className="social-icon" />
                    </a>
                  </span>
                </div>
                <div
                  className="col-span-1 flex items-center justify-center"
                  data-aos="zoom-in-down"
                  data-aos-delay="300"
                >
                  <span>
                    <a
                      href={facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="facebook"
                    >
                      <FaBookReader className="social-icon" />
                    </a>
                  </span>
                </div>
                {/* 222 */}
              </div>
            </div>
          </div>
        </div>
        <div className="relative overflow-hidden">
          <div className="bg-gray-700">
            <div className="container mx-auto bg-white">
              <div className="grid grid-cols-2">
                <div className="col-span-2 lg:col-span-1 bg-yellow-100 z-10">
                  <BackgroundImage
                    Tag="div"
                    {...bgImage2}
                    preserveStackingContext
                    className="h-96 lg:h-full w-full bg-top nodraggable"
                  ></BackgroundImage>
                </div>
                <div className="col-span-2 lg:col-span-1 order-first relative bg-white z-10">
                  <div className="bg-gray-200 р-20 flex items-center justify-center relative">
                    <div className="h-20"></div>
                  </div>
                  <div
                    className="p-10 md:p-20 lg:p-10 xl:p-20"
                    data-aos="fade-down"
                    data-aos-delay="400"
                  >
                    <PortableText textData={_rawBody} data-aos="zoom-in-down" />
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute bottom-10 z-0 bg-red-700 w-full h-20" />
          </div>
        </div>

        <div className="bg-red-700 h-20" />
        <YouTubePlayer url={youTubeLink} youtubeLink={youtube} />

        {/* Photo block */}

        <div className="overflow-hidden bg-white">
          <div className="bg-gray-700 h-5 relative z-20"></div>
          <div className="relative flex items-center justify-center p-5 md:p-20 z-10">
            <ThumbnailGalleryCoomponent gallery={gallery} />
            <div className="absolute z-0 bottom-10 bg-blue-100 bg-opacity-40 w-1/2 h-full rounded-3xl shadow"></div>
          </div>
        </div>

        {/* Audio block */}
        <SoundCloudPlayer
          soundcloudplaylist={soundcloudplaylist}
          soundcloud={soundcloud}
          anchor={anchor}
          _rawAudiotext={_rawAudiotext}
          spotify={spotify}
          applePodcasts={applePodcasts}
          googlePodcasts={googlePodcasts}
          breaker={breaker}
        />
        {/* Sponsor block  */}
        <div className="relative bg-gray-700 z-10">
          <div className="flex items-center justify-center text-white font-sans text-4xl h-20">
            При поддержке
          </div>
        </div>
        <div className="relative bg-black flex items-center justify-center z-10">
          <div className="m-10">
            {sponsorList.sponsorList.map((sponsor, index) => (
              <div className="flex items-center justify-center" key={index}>
                <div className="flex items-center justify-center max-w-3xl">
                  <a
                    href={sponsor.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="finanzas de bolsillo"
                  >
                    <Image
                      // pass asset, hotspot, and crop fields
                      {...sponsor._rawSponsorImage}
                      // className="diplomas-thumbs"
                      data-aos="zoom-in-down"
                      data-aos-delay="300"
                      // tell Sanity how large to make the image (does not set any CSS)
                      width={700}
                      alt={sponsor.title}
                      className="w-full nodraggable"
                      // style it how you want it
                      style={{
                        objectFit: "cover",
                      }}
                    />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Contact block */}

        <div className="relative z-10 flex items-center justify-center font-sans text-4xl h-20 bg-red-800 text-white">
          Контакты
        </div>

        <div className="relative container mx-auto z-10 bg-white rounded-b-lg">
          <div className="grid grid-cols-2 gap-5">
            <div className="col-span-2 lg:col-span-1 p-10 md:p-20 lg:p-10 xl:p-20">
              <Image
                // pass asset, hotspot, and crop fields
                {..._rawContactImage}
                width={300}
                alt="Ольга Амосова"
                className="rounded shadow nodraggable"
                // style it how you want it
                style={{
                  objectFit: "cover",
                  height: "100%",
                }}
              />
            </div>
            <div className="flex flex-wrap content-between col-span-2 lg:col-span-1 p-10 md:p-20 lg:p-10 xl:p-20">
              <div className="grid grid-cols-5 gap-5 content-between w-full pb-20 md:pb-0">
                <div
                  className="col-span-1"
                  data-aos="zoom-in-down"
                  data-aos-delay="300"
                >
                  <span>
                    <a
                      href={youtube}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="youtube"
                    >
                      <FaYoutube className="social-icon" />
                    </a>
                  </span>
                </div>
                <div
                  className="col-span-1"
                  data-aos="zoom-in-down"
                  data-aos-delay="300"
                >
                  <span>
                    <a
                      href={instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="instagram"
                    >
                      <FaDiceOne className="social-icon" />
                    </a>
                  </span>
                </div>
                <div
                  className="col-span-1"
                  data-aos="zoom-in-down"
                  data-aos-delay="300"
                >
                  <span>
                    <a
                      href={vk}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="vk"
                    >
                      <FaVk className="social-icon" />
                    </a>
                  </span>
                </div>
                <div
                  className="col-span-1"
                  data-aos="zoom-in-down"
                  data-aos-delay="300"
                >
                  <span>
                    <a
                      href={soundcloud}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="soundcloud"
                    >
                      <FaSoundcloud className="social-icon" />
                    </a>
                  </span>
                </div>
                <div
                  className="col-span-1"
                  data-aos="zoom-in-down"
                  data-aos-delay="300"
                >
                  <span>
                    <a
                      href={facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="facebook"
                    >
                      <FaBookReader className="social-icon" />
                    </a>
                  </span>
                </div>
                <div
                  className="col-span-1"
                  data-aos="zoom-in-down"
                  data-aos-delay="300"
                >
                  <span>
                    <a
                      href={anchor}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="anchor"
                    >
                      <SiAnchor className="social-icon" />
                    </a>
                  </span>
                </div>
                <div
                  className="col-span-1"
                  data-aos="zoom-in-down"
                  data-aos-delay="300"
                >
                  <span>
                    <a
                      href={applePodcasts}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="applepodcasts"
                    >
                      <SiApplepodcasts className="social-icon" />
                    </a>
                  </span>
                </div>
                <div
                  className="col-span-1"
                  data-aos="zoom-in-down"
                  data-aos-delay="300"
                >
                  <span>
                    <a
                      href={googlePodcasts}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="googlepodcasts"
                    >
                      <SiGooglepodcasts className="social-icon" />
                    </a>
                  </span>
                </div>
                <div
                  className="col-span-1"
                  data-aos="zoom-in-down"
                  data-aos-delay="300"
                >
                  <span>
                    <a
                      href={breaker}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="breaker"
                    >
                      <SiBreaker className="social-icon" />
                    </a>
                  </span>
                </div>
                <div
                  className="col-span-1"
                  data-aos="zoom-in-down"
                  data-aos-delay="300"
                >
                  <span>
                    <a
                      href={spotify}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="spotify"
                    >
                      <SiSpotify className="social-icon" />
                    </a>
                  </span>
                </div>
              </div>
              <div className="" data-aos="zoom-in-down" data-aos-delay="300">
                <PortableText textData={_rawLetter} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    sanityHome {
      title
      header
      date

      _rawBody(resolveReferences: { maxDepth: 10 })
      _rawAudiotext(resolveReferences: { maxDepth: 10 })
      _rawHeaderImage(resolveReferences: { maxDepth: 10 })
      mainImage {
        asset {
          gatsbyImageData(formats: [AUTO, WEBP, AVIF])
        }
      }
      gallery {
        _rawImages(resolveReferences: { maxDepth: 10 })
      }
      youTubeLink
      sponsorList {
        sponsorList {
          _rawSponsorImage(resolveReferences: { maxDepth: 10 })
          title
          url
        }
      }
      _rawContactImage(resolveReferences: { maxDepth: 10 })
      _rawLetter(resolveReferences: { maxDepth: 10 })
      facebook
      soundcloud
      instagram
      vk
      youtube
      applePodcasts
      breaker
      soundcloudplaylist
      spotify
      anchor
      googlePodcasts
    }
    sanitySeoData {
      title
      description
    }
    placeholderImage: file(relativePath: { eq: "olga-amosova-2000px.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(formats: [AUTO, WEBP, AVIF], quality: 100)
      }
    }
  }
`

export default IndexPage
