import React from "react"
import { FaYoutube } from "react-icons/fa"
import ReactPlayer from "react-player/lazy"

const Youtube = ({ url, youtubeLink }) => {
  return (
    <div className=" bg-black flex items-center justify-center triangle-png pb-20 bg-fixed bg-top">
      <div className="w-4/5 md:w-3/6">
        <div className="relative video-container mt-10" data-aos="fade-down">
          <ReactPlayer
            className="video w-full"
            url={url}
            style={{ zIndex: "10" }}
            width="100%"
            height="100%"
          />
          <div className="absolute bg-red-300 w-full h-full rounded-3xl transform rotate-3 shadow"></div>
          <div className="absolute bg-red-200 w-full h-full rounded-3xl transform -rotate-6 shadow"></div>
          <div className="absolute bg-red-400 w-full h-full rounded-3xl transform rotate-12 z-0 shadow"></div>
        </div>

        <div className="pt-5 md:pt-10 container mx-auto w-full">
          <div className="flex items-center justify-center">
            <span className="text-white uppercase font-sans text-xl md:text-3xl flex text-center z-10">
              YouTube-канал Ольги Амосовой
              <br />
              Подписывайтесь!
            </span>
          </div>
          <div className="flex items-center justify-center">
            <a
              href={youtubeLink}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="youtube"
            >
              <FaYoutube className="text-gray-300 text-7xl z-10 hover:text-white" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Youtube
