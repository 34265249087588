import React from "react"

import { FaSoundcloud } from "react-icons/fa"
import { SiAnchor } from "react-icons/si"
import { SiApplepodcasts } from "react-icons/si"
import { SiGooglepodcasts } from "react-icons/si"
import { SiBreaker } from "react-icons/si"
import { SiSpotify } from "react-icons/si"

import PortableText from "../components/PortableText"

const SoundCloud = ({
  soundcloudplaylist,
  soundcloud,
  anchor,
  _rawAudiotext,
  spotify,
  applePodcasts,
  googlePodcasts,
  breaker,
}) => {
  return (
    <>
      <div className="relative bg-gray-700 z-10">
        <div className="flex items-center justify-center text-white font-sans text-4xl h-20">
          Аудио
        </div>
      </div>
      <div className="bg-white">
        <div className="container mx-auto bg-white">
          <div className="grid grid-cols-2 gap-5">
            <div className="col-span-2 lg:col-span-1 p-10 md:p-20 lg:p-10 xl:p-20 h-full">
              <div className="relative h-screen lg:h-full z-10">
                <iframe
                  title="Альбомы певицы Ольги Амосовой"
                  width="100%"
                  height="100%"
                  scrolling="yes"
                  className="rounded shadow"
                  frameBorder="no"
                  allow="autoplay"
                  src={soundcloudplaylist}
                ></iframe>
              </div>
            </div>

            <div className="col-span-2 lg:col-span-1 p-10 md:p-20 lg:p-10 xl:p-20">
              <div className="relative grid grid-cols-6 gap-5 z-10 rounded">
                <div
                  className="col-span-1"
                  data-aos="zoom-in-down"
                  data-aos-delay="300"
                >
                  <span>
                    <a
                      href={soundcloud}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="soundcloud"
                    >
                      <FaSoundcloud className="social-icon" />
                    </a>
                  </span>
                </div>
                <div
                  className="col-span-1"
                  data-aos="zoom-in-down"
                  data-aos-delay="300"
                >
                  <span>
                    <a
                      href={anchor}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="anchor"
                    >
                      <SiAnchor className="social-icon" />
                    </a>
                  </span>
                </div>
                <div
                  className="col-span-1"
                  data-aos="zoom-in-down"
                  data-aos-delay="300"
                >
                  <span>
                    <a
                      href={applePodcasts}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="applepodcasts"
                    >
                      <SiApplepodcasts className="social-icon" />
                    </a>
                  </span>
                </div>
                <div
                  className="col-span-1"
                  data-aos="zoom-in-down"
                  data-aos-delay="300"
                >
                  <span>
                    <a
                      href={googlePodcasts}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="googlepodcasts"
                    >
                      <SiGooglepodcasts className="social-icon" />
                    </a>
                  </span>
                </div>
                <div
                  className="col-span-1"
                  data-aos="zoom-in-down"
                  data-aos-delay="300"
                >
                  <span>
                    <a
                      href={breaker}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="breaker"
                    >
                      <SiBreaker className="social-icon" />
                    </a>
                  </span>
                </div>
                <div
                  className="col-span-1"
                  data-aos="zoom-in-down"
                  data-aos-delay="300"
                >
                  <span>
                    <a
                      href={spotify}
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="spotify"
                    >
                      <SiSpotify className="social-icon" />
                    </a>
                  </span>
                </div>
                <div
                  className="col-span-6"
                  data-aos="zoom-in-down"
                  data-aos-delay="300"
                >
                  <PortableText textData={_rawAudiotext} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SoundCloud
